

.pep-box {
    display: flex;
    width: 360px;
    height: 72px;
    align-items: center;
    gap: 20px;
    padding-left: 30px;
}
.pep-box .icon-bg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #FFFFFF;
}

.pep-box .icon-bg img {
    margin: 9px 9px 8px 9px;
    width: 18px;
    height: 18px;
}

.pep-box.percentage-box {
    border: 1px solid #35A2EE;
    border-radius: 10px;
    background-color: #EBF6FD;
}

.pep-box.reject {
    border: 1px solid #D40011;
    border-radius: 10px;
    background-color: #FFECED;
}

.pep-box.info {
    border: 1px solid #D0B64F;
    border-radius: 10px;
    background-color: #FFF5CB;
}


.pep-box.success {
    border: 1px solid #00C108;
    border-radius: 10px;
    background-color: #E5FFE8;
}

.pep-box .pep-result-text {
    color: #4A6275;
    font-size: 18px;
    font-weight: 700;
    line-height: 26.4px;
    /* letter-spacing: -1px; */
}

.pep-box.reject .pep-result-text {
    color: #D40011;
}

.pep-box.percentage-box .icon-bg img {
    margin: 9px 9px 8px 9px !important;
}

.pep-result-percentage {
    font-size: 32px;
    font-weight: 700;
    line-height: 50.4px;
    letter-spacing: -1px;
    color: #4A6275;
}

.pep-result-info {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    /* letter-spacing: -0.5px; */
}