body {
  font-family: "Urbanist" !important;
  overflow: hidden;
}

.urban-font-family {
  font-family: "Urbanist" !important;
}

.local-row {
  display: flex;
}

.local-row.align-start {
  align-items: flex-start;
}

.local-row.gap {
  gap: 30px;
}

.local-row.medium-gap {
  gap: 20px;
}

.local-row.small-gap {
  gap: 15px;
}

.local-row.column-direction {
  flex-direction: column !important;
}

.local-row.justify-center {
  justify-content: center;
}

.local-row.align-items-center {
  align-items: center;
}

.hidden {
  display: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading span {
  color: #35A2EE;
  margin-left: 10px;
}

@media screen and (min-height: 718px)  {
  .desktop-hide {
    display: none !important;
  }
}

@media screen and (max-height: 718px)  {
  .mobile-hide {
    display: none !important;
  }
}

.error {
  color: red;
}

body:has(.login-container) {
  height: unset;
}

.login-container {
  width: 700px;
  margin: 100px auto 0 auto;
  background: #FFF;
  align-items: center;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
}

.login-container .login-regulo-icon {
  height: 80px;
  width: 200px;
  margin-top: 50px;
}

.login-container .login-title {
  font-size: 16px;
  color: #586E7F;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-top: 20px;
  text-transform: uppercase;
}


.login-container .login-form {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-container .login-label {
  font-size: 14px;
  line-height: 21.6px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  color:#000000;
}

.login-container .login-input {
  border: 1px solid #CCCCCC;
  /* color: #CCCCCC; */
  background-color: #FFF;
  border-radius: 10px;
  width: 380px;
  height: 48px;
  padding: 20px;
}

.login-container .login-input:focus {
  border: 1px solid #CCCCCC;
}

.login-container .login-btn {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-top: 20px;
  letter-spacing: 0.6px;
}

.login-container .login-request {
  color: #35A2EE;
  border: unset;
  background: unset;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.header {
  background-color: #FFF;
  height: 70px;
  margin-left: 210px;
  width: calc(100% - 210px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
}

.header-container {
  margin-left: 37px;
  display: flex;
  align-items: center;
}

.header-container img {
  margin-right: 62px;
}

.header-container h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Outfit";
  color: #4A6275;
  margin: auto 0;
}

.header-regulo-icon {
  height: 34px;
}

.header-profile {
  padding-right: 37px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.header-profile .profile-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.profile-container {
  display: flex;
  flex-direction: column;
  place-items: flex-start;
}

.profile-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1;
  color: #4A6275;
}

.profile-role {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 1;
  color: #4A6275;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.profile-popup-container {
  cursor: unset;
  position: absolute;
  width: 360px;
  height: 620px;
  background-color: #FFFFFF;
  z-index: 1000;
  right: 37px;
  bottom: -100%;
  top: 70px;
  border: 1px solid #CBCDCF;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* opacity: 0;
  visibility: hidden; */
  transition: opacity 2s, visibility 2s, bottom 2s;
}

.header-profile.show-popup .profile-popup-container{
  opacity: 1;
  visibility: visible;
  bottom: 100%;
}

.profile-popup-container .apply-padding {
  padding: 40px;
  padding-bottom: 20px;
}

.profile-popup-container .apply-padding.general-settings {
  padding-top: 20px;
}

.profile-popup-container .title {
  color: #586E7F;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}

.profile-popup-container .label {
  color: #BCC1C5;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.profile-popup-container .text-css {
  color: #4A6275;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
}

.profile-popup-container .separator {
  border: 1px solid #BCC1C5;
}

.profile-popup-container .company-logo {
  height: 96px;
  width: 96px;
}

.profile-popup-container .button {
  font-family: 'Outfit';
  background-color: #FFF;
  display: inline-block;
  color: #35A2EE;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-align: center;
  border: unset;
  margin-top: -20px;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
}

.profile-popup-container .logout-button {
  padding: 20px 0 0 40px;
}

.profile-popup-container .logout-btn {
  color: #4A6275;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.4px;
  margin: 0;
  display: flex;
  gap: 10px;
}

.profile-popup-container .profile-input {
  border: 2px solid #BCC1C5;
  border-radius: 10px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.4px;
  padding-left: 10px;
  margin-bottom: 4px;
}

.profile-popup-container input.profile-input:focus {
  border: 2px solid #BCC1C5;
}
.body-container {
  display: flex;
  margin-top: 70px;
  gap: 10%;
}

.inner-body-container {
  margin: 30px 50px;
  display: flex;
  gap: 20px;
}

.inner-body-container.center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inner-body-container .small-size {
  height: 305px;
  width: 244px;
}

.inner-body-container .extra-small-size {
  height: 140px;
  width: 112px;
}

.inner-body-container .extra-extra-small-size {
  /* height: 96px; */
  width: 112px;
}
.sidebar-padding-imp {
  padding: 6px 10px !important;
}

.sidebar-container {
  margin-top: -70px !important;
  height: calc(100vh);
  position: fixed;
  background-color: #103147;
  width: 210px;
  margin-top: -2px;
  z-index: 1999;
}

@media screen and (max-height: 720px) {
  .sidebar-container {
    position: absolute;
    overflow-y: scroll;
  }
  .inner-body-container {
    max-height: calc(100vh - 230px);
    overflow-y: scroll;
  }
}

.sidebar-header {
  margin: 20px;
  display: flex;
}

.sidebar {
  flex: 1;
  position: sticky;
}

.sidebar .section:first-child {
  border-top: 1px solid #103147;
}

.sidebar .section {
  display: flex;
  flex-direction: column;
  background: #103147;
  border-radius: 2px;
  padding: 0 10px;
  /* gap: 12px; */
  border-top: 8px solid #274559;
}

.sidebar .section .pages {
  /* padding: 6px 10px; */
  padding: 10px;
}

.sidebar .section .pages:hover {
  background-color: #274559;
  border-radius: 10px;
  border: unset;
}

.sidebar .section .pages img {
  height: 28px;
  width: 28px;
  background-size: 28px * 28px;
}

.sidebar .section .pages:last-child {
  margin-bottom: 10px;
}

.sidebar .section .flex-end {
  bottom: 12px;
  position: fixed;
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  width: 190px;
}

.sidebar .section:first-child {
  margin-top: 2px !important;
  padding-top: 10px;
}

.sidebar .pages {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: unset;
}

.sidebar .pages.active {
  background-color: #FFFFFF;
  border-radius: 10px;
  border: unset;
}

.sidebar .pages.active:hover {
  background-color: #FFFFFF;
}
.sidebar .pages.active .name {
  font-weight: 600;
  color: #103147;
}

.sidebar .pages.active img {
  fill: #FFF !important;
}

.sidebar .title {
  font-family: "Outfit";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.sidebar .pages .name {
  font-family: 'Outfit';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
}

.body {
  flex: 1;
  background: #FFF;
  margin: 20px;
  margin-bottom: 0px;
  border: 1px solid #E4E4e4;
  border-radius: 5px;
  margin-left: 230px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
}

.body.preferences {
  background-color: unset !important;
  border: unset;
  display: flex;
  flex-direction: column;
}

.dashboard {
  flex: 1;
  margin: 20px;
  margin-left: 230px;
  /* width: 75%; */
  /* z-index: 999; */
}

.dashboard .gap {
  gap: 20px !important;
}

.dashboard-card {
  margin: 20px;
  margin-bottom: 10px !important;
  background-color: #FFFFFF;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}

.dashboard-card-padding {
  border: 10px solid #EEE;
  width: 300px;
  background: #FFF;
  border: 1px solid #E4E4E4;
  border-radius: 10px;
}

.dashboard-card-padding .card-header-icon {
  width: 100%;
  height: 28px;
  margin-top: -12px;
}

.dashboard .card-icon {
  height: 52px;
  width: 52px;
  background-size: 52px;
}

.dashboard .card-title {
  font-family: "Outfit";
  color: #4A6275;
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

.dashboard ol {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  justify-content: stretch;
  height: 100px;
}

.dashboard ol li {
  color: #586E7F;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.4px;
  max-width: 260px;
}

.dashboard .overlay-icon {
  height: 48px;
  width: 48px;
  background-color: #EBF6FD;
  border-radius: 50%;
  /* position: absolute;
  right: 10px;
  bottom: 10px; */
  cursor: pointer;
  text-decoration: unset;
  margin-left: auto;
}

.dashboard .overlay-icon img {
  margin: 12px;
}

.outlet-container {
  display: flex;
  border-bottom: 1px solid #eee;
}

.outlet-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 0 20px;
  /* border-bottom: 2px solid black; */
  text-align: center;
  /* width: 150px; */
  padding: 0 20px 10px 20px;
  cursor: default;
  color: #4A6275;
}

.outlet-title .tick-icon {
  /* margin-top: 10px; */
  margin-left: 8px;
}
.outlet-title:first-child {
  margin-left: 50px;
}

.outlet-title.active {
  color: #35A2EE;
  border-bottom: 3px solid #35A2EE;
  font-weight: 700;
}

.input-container {
  display: flex;
  flex-direction: column; /* To align items vertically */
  border: 2px solid #AEDAF8;
  border-radius: 10px;
  height: 320px;
  width: 256px; 
  text-align: center;
  align-items: center;
  position: relative;
}

.small-size .input-container {
  width: 244px;
  height: 305px;
}

.extra-small-size .input-container {
  width: 112px;
  height: 140px;
}

.extra-extra-small-size .input-container {
  width: 96px;
  height: 112px;
}

.widget-title {
  /* color: #586E7F; */
  color: #A0A9B1;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
}

.overlay-text {
  font-family: 'Outfit';
  position: absolute;
  top: 10%; /* Position at the top middle of the container */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  width: 136px;
  height: 40px;
  line-height: 18px;
  border-radius: 40px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  gap: 15px;
  padding: 0 10px;
}

.overlay-text img.protected {
  width: 16px;
  height: 20px;
}

.extra-small-size .overlay-text {
  width: auto !important;
  top: 15% !important;
  font-size: 12px !important;
  gap: 5px;
  height: 28px !important;
}

.extra-small-size .overlay-text img.protected {
  height: 12px !important;
  width: 12px !important; 
}

.overlay-text-hashed {
  font-family: 'Outfit';
  position: absolute;
  top: 70%; 
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 900;
  color: #FFFFFF;
  letter-spacing: 4px;
  /* box-shadow: 0px 0px 34px 0px #000000; */
  border-radius: 48%; 
  padding: 5px 20px; 
  height: auto;
  width: auto; 
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}

.overlay-text-matched {
  position: absolute;
  background-color: #eee;
  border-radius: 25px;
  color: #4A6275;
  font-size: 10px;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 0.2px;
  padding: 4px 8px;
  bottom: 10px; 
}

.extra-small-size .overlay-text-hashed {
  font-size: 18px !important;
} 

.overlay-text-spinner {
  font-family: 'Outfit';
  position: absolute;
  top: 40%; 
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  height: auto;
  width: auto; 
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay-text-spinner img {
  height: 80px !important;
}

.overlay-text-loading {
  font-family: 'Outfit';
  position: absolute;
  top: 60%; 
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  height: auto;
  width: 70%;
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-image-text{
  font-family: "Outfit";
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  max-width: 90%;
  height: 40px;
  line-height: 18px;
  border-radius: 40px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  padding: 0px 10px;
}

.remove-profile-image {
  font-family: 'Outfit';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  top: 400px;
  left: 100px;
  padding: 3px;
  cursor: pointer;
}

.remove-image-text .filename {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-image-text:hover{
  cursor: pointer;
}

.left-icon {
  position: absolute;
  top: 88%; 
  left: 12%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.4); */
  border: 1px solid #4A6275;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  width: 45px !important;
  height: 56px !important;
  line-height: 18px;
  /* border-radius: 40px; */
  display: flex; 
  align-items: center; 
  justify-content: center;
  /* padding: 0 5px; */
  object-fit: unset !important;
}

.widget-title3 {
  color: #4A6275;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: center;
}

.status-container {
  height: auto;
  width: 320px;
  text-align: center;
  padding: 0 50px;
  /* align-items: center; */
}

.status-icon {
  height: 48px;
  width: 48px;
  background-color: #EBF6FD;
  border-radius: 50%;
  position: relative;
}

.status-icon img {
  margin: 12px;
}

.status-icon .status-vertical-border {
  height: 30px;
  width: 6px;
  background-color: #EBF6FD;
  position: absolute;
  margin-top: 48px;
  margin-left: -27px;
}

.status-title {
  color: #4A6275;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.status-title.no-title {
  background-color: #EBF6FD;
  height: 20px;
  width: 140px;
}

.input-container .input-group {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin: 12px auto;
  height: 212px;
  width: 232px;
  background-color: #EBF6FD;
  border-radius: 10px;
}

.input-container .input-group .input-group-info {
  font-family: "Outfit";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #4A6275;
  padding: 0 30px;
}

.input-container .input-group-title {
  font-family: "Outfit";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #4A6275;
}

.input-container .hash-template {
  display: flex;
  background-color: #EBF6FD;
  border-radius: 10px;
  height: 288px;
  width: 232px;
  margin: 20px auto;
}

.small-size .input-container .hash-template {
  width: 214px;
  height: 265px;
}

.input-container .hash-template .hash-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #35A2EE;
  padding: 0 30px;
}

button[type="button"]:not(.profile-button) {
  font-family: "Outfit";
  width: 100%;
  min-width: 256px;
  background-color: #35A2EE;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  padding: 16px 0;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: unset;
}

button.disabled {
  background-color: #BCC1C5 !important;
  cursor: not-allowed !important;
} 
.zipFileSubmitButton{
  width: 0% !important;
  /* background-color: #BCC1C5 !important; */
}
.input-drop-area {
  border: 2px dashed #ccc;
  border-width: 0px;
  transition: border 0.3s ease;
}

.input-drop-area.dragging {
  border-color: #007bff;
  border-width: 2px;
}

.preview-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-image img:not(.protected) {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.small-size .preview-image img:not(.protected) {
  border-radius: 10px;
  width: 244px;
  height: 305px;
}

.extra-small-size .preview-image img:not(.protected) {
  border-radius: 10px;
  width: 112px;
  height: 140px;
}

.extra-extra-small-size .preview-image img:not(.protected) {
  border-radius: 10px;
  width: 96px;
  height: 112px; 
}

.hash-box-result {
  background-color: #FFECED;
  /* width: 460px; */
  height: 112px;
  border: 2px solid #D40011;
  border-radius: 10px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  padding: 0 50px;
  gap: 30px;
}

.hash-box-result.apply-width {
  width: 460px;
}

.hash-box-result.true {
  justify-content: center;
}

.hash-box-result.expand {
  width: 530px !important;
  justify-content: space-around !important;
}

.hash-box-result.expand img {
  height: 40px;
  width: 40px;
}

.hash-box-result .result-title {
  font-family: 'Outfit';
  color: #D40011;
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.4px;
}

.hash-box-result .result-title.more-font {
  font-size: 32px !important;
}

.hash-box-result .result-info {
  font-family: 'Outfit';
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  letter-spacing: 0.4px;
  color: #D40011;
  margin-top: -10px;
}

.hash-box-result .result-info.more-font {
  font-size: 18px !important;
}

.hash-box-result .result-description {
  color: #00C108;
  font-size: 20px;
  line-height: 28.8px;
  letter-spacing: 0.4px;
}

.hash-box-result .fraud-no-match {
  /* padding: 0 100px; */
  text-align: center;
}

.hash-box-result .hidePercentage {
  visibility: hidden;
}

.hash-box-result.true {
  background-color: #E5FFE8;
  border: 2px solid #00C108;
}

.hash-box-result.true .result-title {
  color:#00C108;
}

.hash-box-result.true .result-info {
  color: #00C108;
}

.hash-box-result img.match-icon {
  height: 40px;
  width: 40px;
}

.face-added-to-db {
  background-color: #EBF6FD;
  width: 320px;
  /* height: 140px; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 0 30px;
}

.face-added-to-db.false {
  justify-content: left;
  width: unset;
}

.face-added-to-db .title {
  font-family: 'Outfit';
  color: #35A2EE;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  width: 164px;
}

.face-added-to-db.false .title {
  color: #4A6275;
}

/* table css */
/* styles.css */
.apply-scroll {
  max-height: calc(100vh - 110px - 210px - 140px); /* last value varies from screen to screen */
  overflow-y: scroll;
}

.pep-screening-table.apply-scroll {
  max-height: calc(100vh - 110px - 210px - 180px);
}
.adverse-table.apply-scroll {
  max-height: calc(100vh - 110px - 210px - 165px);
}

/* .apply-scroll:hover {
  overflow-y: scroll;
} */

.apply-scroll::-webkit-scrollbar-thumb, .custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.apply-scroll::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar {
  width: 10px;
  visibility: hidden;
}

.apply-scroll::-webkit-scrollbar:hover, .custom-scroll::-webkit-scrollbar:hover {
  visibility: visible;
}

.custom-table {
  border-collapse: collapse;
  border: 1px solid #CECECE;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.custom-table th {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.4px;
}

.custom-table th,
.custom-table td {
  padding: 10px;
  text-align: center;
  color: #4A6275;
}

.custom-table thead tr th, .custom-table tbody tr td {
  border-bottom: 1px solid #CECECE !important;
}

.custom-table tbody tr.expanded td {
  border-bottom: unset;
}

.custom-table .view-detials {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.6px;
  text-align: right;
  color: #35A2EE;
  margin-right: -8px;
  cursor: pointer;
}

.custom-table .expanded-data {
  background: #EEE;
}

.custom-table .expanded-data .expanded-data-title {
  color: #4A6275;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  letter-spacing: 0.4px;
  text-align: left;
  margin-bottom: 5px;
}

.custom-table .expanded-data .expanded-data-value {
  color: #4A6275;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}

.custom-table .expanded-data a {
  color: #35A2EE;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  /* letter-spacing: -1px; */
  text-decoration: unset;
}

.face-added-to-db .number {
  font-size: 50px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #35A2EE;
}

.download-btn {
  border: 2px solid #35A2EE !important;
  background-color: #FFF !important;
  border-radius: 10px !important;
  color: #35A2EE !important;
  font-family: "Outfit";
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.6px !important;
  font-weight: 700 !important;
}

.preferences-container {
  padding: 50px 30px;
}

.preferences-container .preferences-title-box {
  border: 1px solid #CECECE;
  border-radius: 10px;
}

.preferences-header.active:first-child {
  border-radius: 10px 10px 0 0;
}

.preferences-header.active:last-child {
  border-radius: 0 0 10px 10px;
}

.preferences-container .preferences-header {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CECECE;
  cursor: pointer;
}

.preferences-container .preferences-header:last-child {
  border-bottom: unset;
}

.preferences-container .preferences-header.active {
  background-color: #EBF6FD;
}

.preferences-container .preferences-header.active .preferences-titles {
  color: #586E7F;
  font-weight: 700;
  line-height: 19px;
}

.preferences-container .preferences-titles {
  color: #A0A9B1;
  font-family: "Outfit";
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  padding-right: 20px;
}

.preferences-container .preferences-header img {
  right: 0;
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
}

.preferences-container .preferences-right-box {
  padding: 20px 10px;
}

.preferences-container .preference-text {
  color: #4A6275;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  /* width: 100%; */
  width: 220px;
}

.preferences-container .preference-text-heading {
  color: #586E7F;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.2px;
}

.preferences-container .countries-button {
  background-color: #FFF !important;
  color: #35A2EE !important;
  font-family: "Outfit";
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.6px;
  min-width: unset !important;
  padding: 0 !important;
  width: unset !important;
}

.preferences-container .preferences-right-box .face-sources .checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.preferences-container .preferences-right-box .checkbox label {
  color:#4A6275;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: 0.4px;
}

.preferences-container .preferences-right-box .checkbox input[type="checkbox"] {
  height: 16px;
  width: 16px;
  margin: auto 0;
  border-radius: 5px;
  border: 1px solid #35A2EE;
}

.preferences-container .preferences-right-box .checkbox input[type="radio"] {
  height: 16px;
  width: 16px;
}

.preferences-container .preferences-right-box .checkbox input[type="checkbox"]:checked {
  background: #FFF;
}

.preferences-container .preferences-right-box .range-text {
  color: #A0A9B1;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.preferences-container .preferences-right-box .range-text .range-value {
  position: absolute;
  top: -20px;
}

/* .table-header {
  border-bottom: 1px solid #CECECE;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.custom-search-container {
  position: absolute;
  width: 300px;
  height: 39px;
  display: flex;
  align-items: center;
  border: 1px solid #000; 
  border-radius: 50px;
  overflow: hidden;

}

.search-icon {
  width: 20px; 
  height: 20px;
  margin-left: 10px; 
}

.custom-search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 10px; 
  font-size: 16px; 
} */

.table-title{
  color: #4A6275;
  font-weight: 700;
}

.table-header {
  border-radius: 5px 5px 0 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CECECE;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.custom-search-container {
  display: flex;
  align-items: center;
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid #BCBCBC; 
}

.search-icon {
  width: 20px; /* Set the width and height of your SVG icon */
  height: 20px;
  margin-left: 10px; /* To add space between the icon and the input */
}

.custom-search-input {
  border: none;
  outline: none;
  padding-left: 10px; /* To add space between the icon and the input */
  font-size: 14px; /* Adjust the font size as needed */
  width: 210px;
  height: 32px;
}

.preferences-footer {
  background-color: #FFFFFF;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #CECECE;
  display: flex;
}

.preferences-footer .left-btns {
  margin-left: 20px;
}

.preferences-footer .right-btns {
  margin-left: auto !important;
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.preferences-footer .save-btn {
  background-color: #FFF !important;
  color: #35A2EE !important;
  border: 2px solid #35A2EE !important;
  border-radius: 10px !important;
  width: unset !important;
  min-width: unset !important;
  /* float: right; */
  padding: 8px 40px !important;
  margin: 15px 0px;
}


.preferences-footer .no-border-btn {
  background-color: #FFF !important;
  color: #35A2EE !important;
  width: unset !important;
  min-width: unset !important;
  padding: 8px 30px !important;
  margin: 15px 0px;
}

.preference-breadcrumb {
  font-family: 'Outfit';
  color: #A0A9B1;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding-bottom: 20px;
}

.preference-breadcrumb .breadcrumb-active {
  color: #758593;
  text-decoration: underline;
}

.preference-breadcrumb .breadcrumb-back-btn {
  font-family: 'Outfit';
  font-weight: 700;
  color: #4A6275;
  font-size: 20px;
  line-height: 20px;
  float: right;
  margin-right: 20px;
}

.preference-breadcrumb .breadcrumb-back-btn:hover {
  cursor: pointer;
}

.form-label {
  color: #BCC1C5;
  font-weight: 600;
  size: 14px;
  line-height: 16.8px;
  letter-spacing: 0.2px;
}

.form-input {
  border: 1px solid #BCC1C5;
  border-radius: 10px;
  color: #BCC1C5;
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
  width: 240px;
}

.pep-form input {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.form-input:focus, .form-input:focus-visible {
  border-color: #BCC1C5 !important;
}

select {
  appearance: none;
}

.position-relative {
  position: relative;
}

.select.down-arrow-icon {
  position: absolute;
  top: 30px;
  right: 10px;
}

.css-tj5bde-Svg {
  color: black !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: #FFFFFF;
  width: 0px !important;
}

.css-t3ipsp-control, .css-t3ipsp-control:hover {
  border: 1px solid #BCC1C5 !important;
  box-shadow: unset !important;
  border-radius: 10px !important;
}
.css-13cymwt-control {
  border: 1px solid #BCC1C5 !important;
  border-radius: 10px !important;
}
.css-1xc3v61-indicatorContainer {
  padding: 8px 10px 8px 8px !important;
}

.pep-form .form-input {
  color: #4A6275;
  font-family: 'Urbanist' !important;
}

.pep-form-group {
  display: flex;
  gap: 5px;
}
.pep-form-group label {
  color: #BCC1C5;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px; 
}

.pep-form-group .pep-value {
  color: #4A6275;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
}

.pep-result {
  border: 1px solid #CECECE;
  border-radius: 10px;
}

.pep-table.custom-table {
  border: none !important;
  /* max-width: 780px !important; */
}

.pep-table.custom-table thead tr th {
  border-bottom: 1px solid #CECECE !important;
  font-size: 14px !important;
  font-weight: 600;
}


.pep-table.custom-table thead tr th:first-child, .pep-table.custom-table tbody tr td:first-child {
  padding-left: 25px !important;
}

.pep-table.custom-table thead tr th:last-child,.pep-table.custom-table tbody tr td:last-child {
  padding-right: 25px !important;
}

.pep-table.custom-table tbody tr td {
  max-width: 120px !important;
}

.custom-table tbody tr:last-child td {
  border-bottom: unset !important;
}

/* .pep-table .expanded {
  background: #EEE;
} */
.pep-table td {
  color: #4A6275;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.4px;
  text-align: center;
}

.pep-table td.left-align {
  text-align: left;
}

.pep-table .success {
  color: #00D624;
  font-weight: 700;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0.4px;
  text-align: center;
}

.pep-table .error {
  color: #D40011;
  font-weight: 700;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0.4px;
  text-align: center;
}

.contries-container {
  background-color: #FFFFFF;
}

.contries-container table.custom-table {
  padding: 20px;
  border: none !important;
}

.contries-container table.custom-table tr {
  border: none !important;
}

.contries-container table.custom-table tr td,th {
  border: none !important;
}

.adverse-media-table-img {
  height: 64px;
  width: 50px;
  border-radius: 10px;
}

.preferences-container {
  background-color: #FFF;
  border-radius: 5px 5px 0 0;
}

.next-image-text {
  color: #4A6275;
  font-size: 16px;
  font-family: "Outfit";
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  padding-bottom: 10px;
}

.pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fade 9s infinite;
  animation: fade 9s infinite;
}
/* #pic1 {
  animation-delay: 0s;
  background-image: url('https://www.devtwins.com/images/graphics/norway1.jpeg');
}
#pic2 {
  background-image: url('https://www.devtwins.com/images/graphics/norway2.jpeg');
  animation-delay: 3s;
}
#pic3 {
  background-image: url('https://www.devtwins.com/images/graphics/norway3.jpeg');
  animation-delay: 6s;
} */
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  border-radius: 5px; /* Adjust the border-radius as needed */
  background: transparent; /* Set background to transparent */
  border: 0px solid #ccc; /* Add a border to the range */
  background-color: #86c7f5;
}

/* Customize the thumb (the slider handle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px; /* Adjust the width of the thumb */
  height: 15px; /* Adjust the height of the thumb */
  border-radius: 50%; /* Make it circular */
  background-color: #35a2ee; /* Default background color for the thumb */
}

input[type="range"]::-moz-range-thumb {
  width: 15px; /* Adjust the width of the thumb */
  height: 15px; /* Adjust the height of the thumb */
  border-radius: 50%; /* Make it circular */
  background-color: #35a2ee; /* Default background color for the thumb */
}

.custom-checkbox {
  display: inline;
  position: relative;
  padding-left: 30px; /* Adjust the spacing between the checkbox and label as needed */
  cursor: pointer;
}

.custom-checkbox input {
  display: none; /* Hide the default checkbox input */
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px; /* Adjust the size of the checkbox as needed */
  height: 20px;
  background-color: white;
  border: 2px solid black; /* Black border */
  border-radius: 2px;
}

.custom-checkbox input:checked + .checkmark:after {
  content: '';
  position: absolute;
  left: 6px; /* Adjust the position of the tick mark as needed */
  top: 1px; /* Adjust the position of the tick mark as needed */
  width: 6px; /* Adjust the size of the tick mark as needed */
  height: 12px; /* Adjust the size of the tick mark as needed */
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Rotate the tick mark to form a checkmark */
}

.blue-checkmark{
  border: 2px solid #35A2EE;
}

.custom-checkbox input:checked + .blue-checkmark:after {
  content: '';
  position: absolute;
  left: 6px; /* Adjust the position of the tick mark as needed */
  top: 1px; /* Adjust the position of the tick mark as needed */
  width: 6px; /* Adjust the size of the tick mark as needed */
  height: 12px; /* Adjust the size of the tick mark as needed */
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Rotate the tick mark to form a checkmark */
  border: solid #35A2EE;
}

.custom-radio {
  display: inline;
  position: relative;
  padding-left: 30px; /* Adjust the spacing between the radio button and label as needed */
  cursor: pointer;
}

.custom-radio input {
  display: none; /* Hide the default radio input */
}

.radio-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px; /* Adjust the size of the radio button circle as needed */
  height: 20px;
  background-color: white;
  border: 2px solid black; /* Black border */
  border-radius: 50%; /* Create a circular shape */
}

.custom-radio input:checked + .radio-circle:after {
  content: '';
  position: absolute;
  top: 5px; /* Adjust the position of the dot as needed */
  left: 5px; /* Adjust the position of the dot as needed */
  width: 8px; /* Adjust the size of the dot as needed */
  height: 8px; /* Adjust the size of the dot as needed */
  border-radius: 50%; /* Make the dot circular */
  background: black; /* Color the dot as black */
}

.contries-table-img{
  width: 48px;
  height: 34px;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}

.form-input {
  flex-grow: 1;
  padding-right: 30px; /* Add padding for the icon */
}

.calendar-icon {
  position: absolute;
  right: 8px; /* Adjust the position as needed */
  cursor: pointer;
}


/* select dropdown css changes */
.css-1nmdiq5-menu {
  margin-top: 0 !important;
  font-family: 'Urbanist';
}

.custom-select-dropdown div div div {
  color: #4A6275;
}

.custom-select-dropdown .css-tr4s17-option {
  color: #FFFFFF;
}

/* date dropdown css changes  */
.rdtPicker {
  margin: 0 5px !important;
  width: 230px !important;
  min-width: unset !important;
}

.rdtSwitch, tr th.dow, .rdtDay {
  font-size: 15px !important;
  color: #4A6275 !important;
}

.rdtPicker td.rdtOld, .rdtPicker td.rdtNew {
  color: #BCC1C5 !important;
}

.rdtPrev, .rdtNext {
  color: #4A6275 !important;
}


.adverse-table, .fraud-table {
  border: 1px solid #CECECE !important;
  border-radius: 10px;
}

.adverse-table tbody tr td {
  border-bottom: unset !important;
  border-left: 1px solid #CECECE !important;
}

.adverse-table thead tr th {
  border-left: 1px solid #CECECE !important;
}
